@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: bangel;
    src: local("bangel"),
    url('./fonts/bangel-ultrablack.otf') format("opentype");
}

body {
    background-color: #f5f5d9;
}

.video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

.bangel {
    font-family: bangel;
}